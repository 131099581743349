@import "../theme/index";

.article-area {
  display: flex;
  justify-content: center;

  &__card-place {
    position: relative;
    width: 310px;
    height: 238px;
    margin: 11px 0 11px 0;
    @include respond-to(largedesktop) {
      width: 400px;
      height: 302px;
      margin: 25px 0 25px 0;
    }
    @include respond-to(widedesktop) {
      width: 416px;
    }
  }

  &__card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 1px solid $alto;
    width: 100%;
    height: 100%;
    padding: 0;
    background: none;
    cursor: pointer;
    &:focus {
      outline: none;
    }
  }

  &__active-card {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    margin-top: 1px;
    background-color: $grey;
  }

  &__title {
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    height: 59px;
    width: 100%;
    font-size: 19px;
    font-weight: bold;
    color: $alternate;
    background-color: $white;
    overflow: hidden;
    border-bottom: 3px solid $grey;

    @include respond-to(largedesktop) {
      height: 75px;
      font-size: 24px;
    }
  }

  &__image{
    max-width: 100%;
    max-height: 100%;
  }

  &__content {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 177px;
    @include respond-to(largedesktop) {
      height: 224px;
    }
  }

  &__card-picture {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    height: 80%;
    background-color: $white;

  }

  &__information {
    width: 50%;
    height: 100%;
    border-left: 1px solid $alto;
  }

  &__description {
    height: 55%;
    font-size: 14px;
    color: $darkGrey;
    padding: 15px 5px 10px 5px;
    text-align: left;
    white-space: pre-wrap;
    overflow: hidden;
    @include respond-to(largedesktop) {
      font-size: 18px;
      padding: 18px 13px 18px 13px;
    }
  }

  &__category {
    align-items: center;
    height: 25%;
    width: 100%;
    background-color: $grey;
    font-size: 11px;
    font-style: italic;
    color: $doveGray;
    padding: 5px;
    text-align: left;
    white-space: pre-wrap;
    overflow: hidden;

    @include respond-to(widedesktop) {
      font-size: 14px;
      padding: 6px 11px 5px 11px;
    }
  }

  &__bottom {
    display: flex;
    position: absolute;
    flex-direction: row;
    width: 50%;
    height: 15%;
    bottom: 0;
    right: 0;
  }

  &__pro-bottom {
    width: 100%;
  }

  &__disabled-bottom {
    pointer-events: none;
  }

  &__add-btn {
    width: 50%;
  }

  &__addition {
    display: flex;
    flex-direction: row;
    height: 94%;
    width: 100%;
    margin-top: 1px;
    padding: 0;

    &-item{
      list-style: none;
      display: flex;
      justify-content: center;
      padding: 1px 0 1px 0;
      height: 100%;
      width: 33%;
      border-right: 3px solid $grey;
      @include respond-to(largedesktop) {
        padding: 4px 0 4px 0;
      }
    }

    &-like {
      display: flex;
      align-items: center;
      padding: 0;
      border-right: 0;
    }
  }

  &__pro-addition {
    width: 50%;
  }

  &__item-img {
    height: 100%;
    width: 40px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    @include respond-to(largedesktop) {
      width: 45px;
    }
    @include respond-to(widedesktop) {
      width: 207px;
    }
  }

  &__check-img {
    width: 122px;
    height: 122px;
    fill: $red;
  }
}
